
import { defineComponent, onMounted, ref, watchEffect, computed } from 'vue';
import { Apollo } from '@/core/services';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { GET_QUOTATION_DETAIL } from '../graphql/Queries';
import { useRoute, useRouter } from 'vue-router';
import { setPageHistory } from '@/core/helpers/toolbar';
import Table from '../../../components/Table/Table.vue';
import { generateAvatar } from '@/core/helpers/generateAvatar';
import InnerLoader from '../../../components/InnerLoader.vue';
import { Select } from '@/components/input-elements';
import { asideTheme } from '@/core/helpers/config';
export default defineComponent({
    name: 'Quotation',
    components: { Table, InnerLoader },

    setup() {
        const route = useRoute();
        const i18n = useI18n();
        const store = useStore();
        const loader = ref(false);
        const quotation = ref([]) as Record<any, any>;
        const customer = ref({}) as Record<any, any>;
        const quotationTotals = ref({}) as Record<any, any>;
        const system_locale = ref();
        const defaultImage = ref(store?.state?.AuthModule?.default_image);

        const columns = ref([
            {
                label: '',
                key: 'image'
            },
            {
                label: 'message.PRODUCT',
                key: 'description'
            },
            {
                label: 'message.QUANTITY',
                key: 'quantity'
            },
            {
                label: 'message.PRICE',
                key: 'price',
                textAlignment: 'end'
            },
            {
                label: 'message.TOTAL',
                key: 'total',
                textAlignment: 'end'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const me = computed(() => {
            return store.getters.getUser;
        });

        const companyLogo = name => {
            return generateAvatar(name);
        };

        const quotationDetails = uuid => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_QUOTATION_DETAIL,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    uuid: uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                quotation.value = data.quotation_detail;
                customer.value = data?.quotation_detail?.customer;
                quotationTotals.value = {};
                if (quotation.value.quotation_total.length > 0) {
                    quotation.value.quotation_total.forEach(element => {
                        quotationTotals.value[element.code] = {
                            title: element.title,
                            value: element.value
                        };
                    });
                }
                loader.value = false;
            });
        };

        const clearPageHistory = () => {
            setPageHistory({});
        };
        onMounted(() => {
            quotationDetails(route.params.uuid);
        });

        return {
            quotation,
            me,
            customer,
            quotationTotals,
            system_locale,
            columns,
            loader,
            clearPageHistory,
            companyLogo,
            i18n,
            asideTheme,
            defaultImage
        };
    }
});
