import { gql } from "graphql-tag";

export const GET_QUOTATION = gql`
	fragment QuotationFields on Quotation {
		id
		uuid
		name
		quotation_signed_status
		is_ordered
		created_at
		quotation_totals
		path
	}
`;

export const QUOTATION_DETAIL = gql`
	fragment QuotationDetailFields on Quotation {
		id
		name
		email
		quotation_signed_status
		is_ordered
		customer {
			id
			uuid
			phone_number
			user_id
			created_by
			user {
			   name
			   email
			   status
			   roles
			}
			parent {
			   id
			   uuid
			   name
			}
			customer_group_id
			customer_group {
			   name
			}
		}
		
		quotation_total {
			value
			title
			code
		}
		site {
			prices_with_tax
		}
		quotation_products {
			product_id
			price
			tax
			quantity
			total
			price_label
			price_label_without_tax
			product {
				id
				description {
					name
				}
				media {
					file_path
				}
			}
		}
		quotation_notes {
			id
			note_type
			note
		}
		created_at
	}
`;

export const PAGINATION = gql`
	fragment Pagination on QuotationPagination {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { GET_QUOTATION, PAGINATION };
