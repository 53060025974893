
import { defineComponent, onMounted } from "vue";
import QuotationDetails from "../components/QuotationDetails.vue";
import {setPageHeader} from "@/core/helpers/toolbar";

export default defineComponent({
    components: {
        QuotationDetails,
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: "message.QUOTATION_DETAILS",
                actionButton: {
                    ability: "",
                    pageAction: {
                        action: "",
                    },
                    button: {},
                },
                breadCrumbs: [
                    { name: "message.GO_BACK", link: "-1" },
                    { name: "message.QUOTATION_DETAILS" },
                ],
            });
        })
    }
});
